export const seo = {
  url: 'firma',
  title: {
    pl: 'Kontenery Morskie | O Firmie',
    en: 'Transport Company | Career | Cooperation',
  },
  desc: {
    pl: `✓ Kontenery Morskie ✓ Kontenery 20'DV ✓ Kontener 40'DV ✓Sklep z kontenerami ✓Szybka realizacja`,
    en: `Welcome to Omida Logistics - the most recognizable Polish brand of TSL. Find out more about the company's news, our activities and careers in the TSL industry.`,
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Firma',
    en: 'Company',
  },
  desc: {
    pl: 'Poznaj Omida Trade - polskiego dystrybutora kontenerów morskich.',
    en: `Welcome to Omida Logistics - the most recognizable Polish brand of TSL. Find out more about the company's news, our activities and careers in the TSL industry.`,
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Learn more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Dystrybutor kontenerów morskich',
        en: 'Polish Transport, Forwarding and Logistics Company',
      },
      texts: [
        {
          pl: `<div>Omida Trade to dystrybutor kontenerów morskich. Zajmujemy się sprzedażą, transportem oraz składowaniem kontenerów. W ramach naszych usług personalizujemy kontenery morskie.</div>`,
          en: '',
        },
      ],
    },
  ],
}
